@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.container {
  display: flex;
  max-width: 100vw;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.loading {
  margin: 45vh auto 0 auto;
}
