@import './reset.scss';
@import './typography.scss';
@import './variables.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --playlist-page-background: 0, 0, 0;
}

body {
  @include font-defaults-new;
  color: #fff;
  line-height: 1.3;
  background-color: black;

  &.ReactModal__Body--open {
    overflow: hidden;
  }

  & .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  & .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

/* Onboarding page */
html:has(body[data-page='onboarding']) {
  height: 100%;
  overflow: hidden;
  position: relative;

  body {
    height: 100%;
    overflow: auto;
    position: relative;
  }
}

/* Background on pages */
body[data-page='setlist'],
body[data-page='playlist'] {
  background: linear-gradient(
      0deg,
      #000 10%,
      rgba(var(--playlist-page-background), 0.55) 90%
    ),
    #000;
  background-size: 100% 230px;
  background-repeat: no-repeat;
}

body[data-page='pair'],
body[data-page='login'],
body[data-page='reset-password'] {
  background: $color-g-spotlight;
}

/* react-modal: hide outline */
.ReactModal__Content {
  &:focus,
  &:focus-visible {
    outline: transparent;
  }
}

/* Input Stripe Credit Card */
.StripeElement {
  width: 100%;
  padding: 14px 20px;
}

/* button upload playlist page */
/* file: form-search-upload.tsx */
.labelUpload {
  @include font-defaults-new;
  font-size: 18px;
  line-height: 24px;
  color: $color-acqua-500;
  cursor: pointer;
}

/* alert toast animation */
/* file: toast-container.tsx */
@keyframes toast_animate_enter {
  0% {
    opacity: 0;
    max-height: 0px;
  }

  100% {
    opacity: 1;
    max-height: 300px;
  }
}

@keyframes toast_animate_leave {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.alert-toast-enter {
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: toast_animate_enter;
  animation-timing-function: linear;
}

.alert-toast-exit-active {
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: toast_animate_leave;
  animation-timing-function: linear;
}

button.not-as-button {
  border: 0;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;

  &[disabled] {
    cursor: wait;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}

/* Onboarding */
/* Left */
.onboarding-title-left-enter p {
  opacity: 0;
  transform: translateX(-50%);
}

.onboarding-title-left-enter-active p {
  opacity: 1;
  transform: translateX(0%);
}

.onboarding-title-left-exit p {
  opacity: 1;
  transform: translateX(0%);
}

.onboarding-title-left-exit-active p {
  opacity: 0;
  transform: translateX(50%);
}

.onboarding-title-left-enter-active p,
.onboarding-title-left-exit-active p {
  transition: opacity 300ms, transform 300ms;
}

/* Right */
.onboarding-title-right-enter p {
  opacity: 0;
  transform: translateX(50%);
}

.onboarding-title-right-enter-active p {
  opacity: 1;
  transform: translateX(0%);
}

.onboarding-title-right-exit p {
  opacity: 1;
  transform: translateX(0%);
}

.onboarding-title-right-exit-active p {
  opacity: 0;
  transform: translateX(-50%);
}

.onboarding-title-right-exit-active p,
.onboarding-title-right-enter-active p {
  transition: opacity 300ms, transform 300ms;
}
